.mainNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    color: #111111;

    .nav {
        width: 100%;

        .dropdown-menu {
            padding: 0.25rem 1.5rem;

            .dropdown-item {
                padding: 0;
            }
        }

        a {
            height: 80px;
            display: flex;
            align-items: center;
            text-decoration: none;
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
            margin-right: 50px;
            color: #111111;
            text-transform: uppercase;
            border-bottom: 2px solid transparent;

            &:hover {
                border-bottom: 2px solid #F0F712;
                background: transparent !important;
            }

            &:nth-last-of-type {
                margin-right: 0;
            }
        }
    }

    .navHome {
        margin-left: 64px;

        img {
            width: 145px;
            height: 57px;
        }
    }

    .navList {
        display: flex;
        list-style: none;
        margin: 0;
        justify-content: center;
        align-items: center;
        width: 100%;


    }

    .navClient {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 384px;
        width: 100%;


        .language {
            display: flex;
            align-items: center;
            width: 85px;

            .languages {
                display: none;
                width: 85px;
                text-align: end;
                height: 87px;
                padding: 12px 12px 12px 8px;
                margin-top: 45px;
                box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
                flex-direction: column;
                align-items: start;
                justify-content: space-between;
                background: #FFFFFF;
                z-index: 15;

                .languageButton {
                    padding: 0 20px 0 0;
                }
            }

            a {
                border: none;
                font-family: Druk Wide Cyr, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 15px;
                letter-spacing: 0;
                text-align: left;
                background: transparent;
                color: #a6a6a6 !important;
                padding: 0;
                margin: 0 0 0 23px;
                height: unset;
                display: block;

                &:hover {
                    color: #111111 !important;
                }
            }

            .languageButton {
                display: flex;
                align-items: center;
                border: none;
                font-family: Druk Wide Cyr, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                width: 85px;
                justify-content: space-between;
                line-height: 15px;
                letter-spacing: 0;
                text-align: left;
                color: #111111;
                background: transparent;
                margin: 0 0 0 0 !important;
                padding: 0 12px 0 8px;
                cursor: pointer;

                &:focus {
                    outline: 0;
                    box-shadow: none !important;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 280px;
            width: 100%;
            height: 80px;
            color: #111111;
            background: #F0F712;
            margin-left: 42px;

            span {
                font-family: Druk Wide Cyr, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0;
                text-align: center;
                margin-left: 13px;
                text-transform: uppercase;
            }
        }
    }

}

.sectionMain {
    .img-fluid {
        min-height: 468px;
        object-fit: cover;
    }

    div:first-child {
        height: 100%;
    }

    .btnLeft,
    .btnRight {
        background: none;
        appearance: none;
        border: none;
        cursor: pointer;
        padding: 0;
    }

    .btnRight {
        margin-left: 35px;
    }

    .btnLeft {
        margin-right: 35px;
    }

    .sectionText {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        left: 26%;
        width: 53.75%;
        text-transform: uppercase;

        h1 {
            font-family: Druk Wide Cyr, serif;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 62px;
            letter-spacing: 0;
            text-align: left;
            color: #FFFFFF;
            text-transform: uppercase;

        }
    }

    .btnNormal1 {
        background: #F0F712;
    }

    .btnNorm {
        border-radius: unset !important;
        max-width: 233px;
        height: 60px;
        width: auto;
        font-family: Druk Wide Cyr, serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.owl-nav {
    display: none;
}

.gym {
    margin-top: 76px;
    display: flex;
    justify-content: space-around;

    .onlineGym {
        font-family: Druk Wide Cyr, serif;
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: 120px;
        letter-spacing: 0;
        color: #F7F7F7;
        position: absolute;
        right: 0;
        z-index: -1;
        top: -60px;
    }

    .gymTitle {
        font-family: Druk Wide Cyr, serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0;
        text-align: center;
        margin: 0 auto 30px auto;
        max-width: 860px;
    }

    .gymSubTitle {
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.03em;
        text-align: center;
        text-transform: uppercase;
    }

    .gymImages {
        .gymItem {
            min-width: 258px;
        }

        .gymSvgItems {
            height: 91px;

            img {
                color: #111111;
            }
        }

        .yellowUnderline {
            width: 24px;
            border-bottom: 1px solid #F0F712;
            margin: 30px auto 0 auto;
        }

        div {
            p {
                max-width: 198px;
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}

#sideMenuOverlay {
    display: none;
}

.sideMenu {
    display: none;
}

.auth-wrapper {
    .aside-wrapper {
        min-height: 340px;

        .login-aside {
            min-height: 340px;
        }
    }

    .first-step {
        min-height: 340px;
    }

    .second-step {
        min-height: 340px;
    }

    .third-step {
        min-height: 340px;
    }

    .four-step {
        min-height: 340px;
    }
}

.program {
    .program-header {
        font-family: Druk Wide Cyr, serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0;
        text-align: center;
        margin-top: 70px;
        margin-bottom: 30px;
    }

    .owl-carousel {
        margin-left: 0;
        max-width: calc(330px * 4);

        .owl-item {
            //max-width: 308px!important;
        }
    }

    .owl-item {
        width: 308px !important;
    }
}

.subscribe {
    h6 {
        margin: 70px auto 8px auto;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.03em;
        text-align: center;

    }

    h2 {
        margin: 0 auto 30px auto;
        font-family: Druk Wide Cyr, serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 860px;
    }

    .subscribe-item {
        background-image: linear-gradient(rgba(20, 18, 25, 0.5) 0%, rgba(20, 18, 25, 0.5) 100%), url(/images/subscribe.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        max-width: 480px;
        max-height: 494px;
        height: 494px;

        h3 {
            font-family: Druk Wide Cyr, serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: 0;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 16px;
        }

        a {
            width: 175px !important;
            text-transform: uppercase;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            font-family: Montserrat, serif;
            font-size: 55px;
            font-style: normal;
            font-weight: 700;
            line-height: 66px;
            letter-spacing: 0;
            text-align: center;
            color: #FFFFFF;
            margin: 0;
        }

        span {
            font-family: Montserrat, serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0;
            text-align: center;
            position: relative;
            top: -20px;
        }
    }
}

.preview {
    width: inherit;
    height: 100%;
}


#player {
    display: none;
    max-width: 740px;
    max-height: 416px;
    width: inherit;
}

.about {
    height: 100%;

    .about-items {
        position: relative;
        max-width: 1078px;
    }

    .aboutFirst {
        margin-top: 70px;

        p {
            font-family: Montserrat, serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
            text-align: left;
        }
    }

    .aboutFirst, .aboutSecond {
        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
            letter-spacing: 0;
            text-align: left;
        }
    }

    .aboutSecond {
        margin-top: 100px;
        margin-bottom: 150px;
        padding-left: 0;

        p {
            font-family: Montserrat, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 55px

        }
    }

    .aboutThird {
        margin-bottom: 85px;

        h2 {
            font-family: Druk Wide Cyr;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 39px;
            letter-spacing: 0em;
            text-align: left;
            padding-left: 15px;
            margin-bottom: 30px;
        }

        .aboutThird-back {
            position: absolute;
            right: 75px;
            z-index: -1;
            top: 25px;
        }
    }

    .col-8 {
        height: inherit;
    }

    .imgAbsolute {
        position: absolute;
        right: 0;
        top: 70px;
        height: inherit;

        .img1 {
            position: absolute;
            top: 0;
            right: 0;
        }

        .img2 {
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 354px;
            right: 69px;
            max-width: 569px;
            max-height: 372px;
            width: 100%;
            height: inherit;
        }
    }

    .playButton {
        position: absolute;
        cursor: pointer;
        border-radius: 50%;
    }

    .aboutComment1 {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;

        div {

            img {
                width: 79px;
                height: 79px;
                margin-bottom: 6px;

                &:nth-child(odd) {
                    margin-right: 6px;
                }
            }
        }
    }

    .aboutComment2 {
        p {
            font-family: Montserrat, serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0;
            text-align: left;
            color: #444444;
        }

        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0;
            text-align: left;
            color: #111111;
            margin-bottom: 20px;
        }

        button {
            border: 1px solid transparent;
            background: none;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }

    .opg-numbers {
        margin-top: 103px;
        display: flex;
        flex-direction: column;
        margin-bottom: 84px;

        .opg-number {
            display: flex;
            margin-bottom: 44px;
            max-width: 380px;
            align-items: center;

            span {
                font-family: Druk Wide Cyr;
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 120%;
                text-transform: uppercase;
                color: #111111;
                margin-right: 16px;
            }

            p {
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 120%;
                color: #111111;
                margin-bottom: 0;
            }
        }
    }
}

.expert {
    background: #111111;

    .owl-carousel {
        margin-left: 0;

        .owl-stage {
            display: flex;
            justify-content: center;
        }

        .owl-item {
        }

    }

    h2 {
        font-family: Druk Wide Cyr, serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0;
        text-align: center;
        color: #FFFFFF;
        margin-top: 73px;
    }

    h4 {
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.03em;
        text-align: center;
        color: #FFFFFF;
        margin: 8px auto 30px;
        max-width: 850px;
    }

    .absoluteTop {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
    }

    .absoluteBottom {
        position: absolute;
        bottom: 0 !important;
        left: 0;
        width: 100%;
    }

    .expertBtn {
        button {
            border: 1px solid transparent;
            background: none;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }
    }

    .expertRate {
        position: relative;
        margin-bottom: 86px;
    }

    .trainer {
        min-height: 370px;
        //background-image: linear-gradient(
        //        360deg, #111111 0%, rgba(0, 0, 0, 0) 39.46%), url(/images/expertpicture.png);
        width: 242px;
        height: 370px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        div {
            margin-bottom: 8px;
        }

        h3 {
            font-family: Montserrat, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0;
            text-align: left;
            color: #FFFFFF;
        }

        h2 {
            font-family: Montserrat, serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0;
            text-align: left;
            color: #FFFFFF;
            margin-top: 0;
        }
    }
}

.news {
    margin-top: 70px;

    h2 {
        font-family: Druk Wide Cyr, serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 30px;
    }

    h4 {
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.03em;
        text-align: center;
    }

    .news-item {
        .news-item-content {
            height: 129px;
            display: flex;
            align-content: space-between;
            flex-wrap: wrap;

            p {
                height: 80px;
                overflow: hidden;
            }
        }

        h3 {
            font-family: Druk Wide Cyr, serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0;
            text-align: left;
            color: #111111;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        p {
            font-family: Montserrat, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.03em;
            text-align: left;
            margin: auto auto 10px auto;
            color: #777777;
            margin-bottom: 23px;
        }

        button {
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: #111111;
        }
    }

    .newsAbsolute {
        position: absolute;
        top: 8px;
        right: 27px;
        color: white;
        background: black;
        width: 74px;
        height: 58px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        h5 {
            font-family: Montserrat, serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 10px;
            letter-spacing: 0;
            text-align: center;
            color: #ffffff;
        }

        h3 {
            font-family: Montserrat, serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0;
            text-align: center;
            color: #ffffff;
        }
    }
}

.seo {
    margin: 70px auto 70px auto;
    padding: 40px 0 0 50px;

    .seo-img {
        position: absolute;
        top: 0;
        left: 0;
    }

    h2 {
        font-family: Druk Wide Cyr, serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        letter-spacing: 0;
        text-align: left;
        max-width: 368px;
    }

    a {
        font-family: Druk Wide Cyr, serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        border-bottom: 1px solid;
        color: #111111;
    }

    h3 {
        font-family: Druk Wide Cyr, serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;

    }

    p {
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #111111;
    }

    p:first-of-type {
        color: #999999;
    }
}

footer {
    //div{
    //    max-width: 1298px;
    //}
    background: #111111;
    //margin-top: 70px;
    //min-height: 600px;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 74px;

    .footerNav, .line, .promodex {
        max-width: 1298px;
        height: max-content !important;
    }

    .footerNav {
        .footer-main-link {
            height: 80px;
            display: flex;
            align-items: center;
            text-decoration: none;
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
            color: #FFFFFF;

            img {
                width: 155px;
                height: auto;
            }
        }

        ul {
            a {
                height: 80px;
                display: flex;
                align-items: center;
                text-decoration: none;
                font-family: Druk Wide Cyr, serif;
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                letter-spacing: 0;
                text-align: center;
                margin-right: 30px;
                color: #FFFFFF;
            }

            li:last-of-type {
                a {
                    margin-right: 0;
                }
            }
        }
    }

    .footerClient {
        background: #F0F712;
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;

        a {
            width: 263px;
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
            color: #111111;

            &:hover {
                outline: none;
            }
        }
    }

    .footerPromo {

        .d-flex, h6, img {
            margin-bottom: 17px;
        }

        margin-bottom: 25px;

        p {
            margin: 0 12px 17px 0;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #777777;

        }

        h6 {
            margin: 0 12px 17px 0;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: center;
            color: #777777;

        }
    }

    .absoluteTop {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
    }

    .line {
        border-bottom: 1px solid #444444;
        margin-bottom: 23px;
    }
}


//user
.mainNavUser {
    background: #F7F7F7;
    z-index: 5;
    width: 100%;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
    color: #111111;

    .menu {
        display: none;
    }

    .navHomeBorder {
        padding-right: 63px;
        border-right: 1px solid #E6E6E6;
        height: 100%;
        display: flex;
        margin-left: 64px;

        img {
            margin: auto;
        }
    }

    .navSearch {
        max-width: 401px;
        min-width: 46px;
        width: inherit;
        position: relative;
        height: 46px;
        overflow: hidden;
        margin-right: auto;
        margin-left: 60px;


        input {
            max-width: 401px;
            width: inherit;
            height: inherit;
            border-radius: 26px;
            border: 1px solid;
            padding: 0 26px;
            background: #FFFFFF;
            //border: none;
            text-overflow: ellipsis;

            &::placeholder {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
            }

            &:focus-visible {
                outline: none
            }
        }

        span {
            position: absolute;
            top: 11px;
            right: 14px;
            line-height: 0;
        }
    }

    .navClient {
        display: flex;
        align-items: center;
        //justify-content: space-between;
        //max-width: 384px;
        //width: 100%;
        button:first-of-type {
            height: 42px;
            width: 184px;
            margin: 0 50px 0 20px;
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
        }

        .language {
            display: flex;
            align-items: center;
            width: 85px;

            .languages {
                display: none;
                width: 85px;
                text-align: end;
                height: 87px;
                padding: 12px 12px 12px 8px;
                margin-top: 45px;
                box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
                flex-direction: column;
                align-items: start;
                justify-content: space-between;
                background: #FFFFFF;
                z-index: 15;

                .languageButton {
                    padding: 0 20px 0 0;
                }
            }

            a {
                border: none;
                font-family: Druk Wide Cyr, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 15px;
                letter-spacing: 0;
                text-align: left;
                color: #a6a6a6;
                margin: 0 0 0 23px;
                background: transparent;
                padding:0;
                height: unset;
                display: block;

            }

            .languageButton {
                display: flex;
                align-items: center;
                border: none;
                font-family: Druk Wide Cyr, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                width: 85px;
                justify-content: space-between;
                line-height: 15px;
                letter-spacing: 0;
                text-align: left;
                color: #111111;
                background: transparent;
                margin: 0 0 0 0 !important;
                padding: 0 12px 0 8px;
                cursor: pointer;
                height: auto;

                &:focus {
                    outline: 0;
                    box-shadow: none !important;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 280px;
            width: 100%;
            height: 80px;
            color: #111111;
            background: #F0F712;
            margin-left: 42px;

            span {
                font-family: Druk Wide Cyr, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0;
                text-align: center;
                margin-left: 13px
            }
        }
    }

    .navClient {
        display: flex;
        align-items: center;

        //div {
        //    select {
        //        border: none;
        //        background-color: #F7F7F7;
        //        font-family: Druk Wide Cyr, serif;
        //        font-size: 12px;
        //        font-style: normal;
        //        font-weight: 500;
        //        line-height: 15px;
        //        letter-spacing: 0;
        //        text-align: left;
        //        -webkit-appearance: none;
        //        option{
        //            background-color: #F7F7F7;
        //            border-color: transparent;
        //        }
        //        &:focus-visible {
        //            outline: none !important;
        //        }
        //    }
        //}


    }

    .clientCabinet {
        display: flex;
        justify-content: space-around;
        align-items: center;

        div:first-of-type {
            display: flex;
            flex-direction: column;
            margin: auto 12px auto 50px;
        }

        div:last-of-type {
            border: 2px solid #E21A3D;
            padding: 2px;
            border-radius: 50%;
            overflow: hidden;
            width: 48px;
            height: 48px;
            margin: auto 60px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        div {
            h2 {
                font-family: Druk Wide Cyr, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0;
                text-align: right;
                margin: 0
            }

            p {
                font-family: Montserrat, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: right;
                margin: 0
            }

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}

.sideNav {
    width: 273px;
    position: fixed;
    padding: 80px 0;
    top: 0;
    left: 0;
    overflow: auto;
    touch-action: none;
    height: 100vh;
    border-right: 1px solid #E6E6E6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sideNavProfile {
        display: none;

        .clientCabinet {
            display: flex;
            justify-content: space-around;
            align-items: center;

            div:last-of-type {
                display: flex;
                flex-direction: column;
            }

            div:first-of-type {
                border: 2px solid #E21A3D;
                padding: 2px;
                border-radius: 50%;
                overflow: hidden;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            div {
                h2 {
                    font-family: Druk Wide Cyr;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin: 0;
                }

                p {
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: right;
                    margin: 0;

                }

                img {
                    width: 40px;
                    height: 40px
                }
            }
        }
    }

    .sideNavTop {
        display: flex;
        flex-direction: column;

        a {
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            color: #111111;
            padding: 19px 0 19px 28px;
        }

        .active {
            background: #111111;
            color: #FFFFFF;
        }
    }

    .sideNavBottom {
        padding: 0 77px 51px 28px;

        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            margin: 50px 0 12px 0;
        }

        p {
            font-family: Montserrat, serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            margin: 0 0 6px 0;

            span {
                font-weight: 700;
            }
        }

        div {
            background: linear-gradient(90deg, #E0006C 0%, #FBE900 52.08%, #5DD420 100%);
            border-radius: 5px;
            transform: rotate(-180deg);
            width: 100%;
            height: 5px;
            margin: 0 0 20px 0;
        }

        button {
            width: 147px;
            height: 42px;
            margin: 0 !important
        }

    }

    .language {
        display: table;
        padding-left: 20px;

        .lang-item {
            margin: 0 0 0 10px !important;
            font-family: Druk Wide Cyr;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #111111
        }

        .lang-item.active-lang {
            color: #999999 !important;
        }
    }
}

.userFooter {
    height: 80px;
    background: #FFFFFF;
    z-index: 2;
    position: relative;
    left: 273px;
    bottom: 0;
    padding: 0;
    margin: 0;
    width: calc(100% - 273px);

    p {
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        color: #999999;
    }
}

//
//userIndex
.content {
    position: relative;
    left: 273px;
    padding-top: 145px;
    width: calc(100% - 273px);
    min-height: calc(100vh - 80px);
}

.program-content {
    .program-content-item {
        margin-bottom: 70px;
    }

    h2 {
        padding-bottom: 30px;
        font-family: Druk Wide Cyr, serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0;
        text-align: left;

    }

    .cardForFitness {
        margin: auto -15px !important;
    }
}

.workoutVideo {
    margin: auto;
    position: relative;
    justify-content: center;
}

.burn-content, .functional-content {
    .youtube-training {
        h2 {
            font-family: Druk Wide Cyr;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: left;

        }

        li {
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;

        }
    }

    .functional-training {
        padding: 10px 20px;
        background-position: center !important;
        background-size: cover !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;

        h2 {
            font-family: Druk Wide Cyr;
            color: #FFFFFF;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
            width: calc(100% - 50px);
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .youtube-header {
        padding: 30px 0 !important;

        h3 {
            font-family: Druk Wide Cyr;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .content-user {
        background: #F7F7F7;
        width: 100%;

        div:last-of-type {
            p {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0;
                text-align: left;
                margin: 0 0 2px 0;
            }

            h2 {
                font-family: Druk Wide Cyr, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0;
                text-align: left;

            }
        }
    }

    .content-user-hide > button {
        width: 244px;
        height: 42px;
        margin: 20px 0 20px auto;
        background: #ffffff;
        border: 1px solid;
        font-family: Druk Wide Cyr, serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;

    }

    .content-user-preview {
        border-bottom: 1px solid #CACACA;
        margin-bottom: 30px;
        padding: 0 0 30px 0;
    }

    .content-user-image {
        border: 2px solid #E21A3D;
        padding: 2px;
        border-radius: 50%;
        overflow: hidden;
        width: 48px;
        height: 48px;
        margin: 15px 12px 15px 25px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            object-position: top;
        }
    }

    .content-user-image-small {
        border-radius: 50%;
        overflow: hidden;
        width: 42px;
        height: 42px;
        background: #FFFFFF;
        margin: 0 12px 0 0;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .content-user-video {
        div:last-child {
            .content-user-video-item {
                margin-bottom: 20px;

                div:last-child {
                    h2 {
                        font-family: Druk Wide Cyr, serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0;
                        text-align: left;
                    }
                }
            }
        }
    }

    .youtube-image-ccal {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin: 0;
            font-family: Montserrat, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0;
            text-align: left;

        }

        div {
            background: #F0F712;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin: auto 8px auto 20px;
        }
    }

    .youtube-save {
        margin-top: 30px;
        background: #111111;
        padding: 24px 40px;

        div {
            h2 {
                font-family: Druk Wide Cyr, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0;
                text-align: left;
                color: #FFFFFF;
            }

            button {
                background: #F0F712;
                padding: 16px 56px;
                font-family: Druk Wide Cyr, serif;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0;
                text-align: center;
            }
        }
    }


    .youtube-comment-write {
        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
            margin: 40px 0 20px 0;
        }

        form {
            textarea::placeholder {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0;
                text-align: left;
                color: #444444;
                margin: 40px 0 20px 0
            }

            textarea {
                width: inherit;
                height: 140px;
                padding: 16px 40px 5px 40px;
                resize: inherit;
                border-color: #CBCBCB;
            }

            button {
                font-family: Druk Wide Cyr, serif;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0;
                text-align: center;
                margin: 20px 0 40px 0;
                padding: 12px 28px;
                background: #111111;
                color: #FFFFFF;
            }
        }

    }

    .youtube-comment {
        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 20px;

            span {
                font-family: Druk Wide Cyr, serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: 0;
                text-align: left;
                color: #e21a3d
            }

        }

        form {
            width: 100%;

            textarea::placeholder {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0;
                text-align: left;
                color: #444444;
                margin: 40px 0 20px 0
            }

            textarea {
                width: inherit;
                height: 140px;
                padding: 16px 40px 5px 40px;
                resize: inherit;
                border-color: #CBCBCB;
            }

            button {
                font-family: Druk Wide Cyr, serif !important;
                font-size: 11px !important;
                font-style: normal !important;
                font-weight: 500 !important;
                line-height: 18px !important;
                letter-spacing: 0 !important;
                text-align: center !important;
                margin: 20px 0 40px 0 !important;
                padding: 12px 28px !important;
                background: #111111 !important;
                color: #FFFFFF !important;
                text-decoration: unset !important;
                width: auto;
            }
        }

        div {
            margin-bottom: 40px;

            p {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0;
                text-align: left;
            }

            button {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0;
                text-align: left;
                color: #111111;
                text-decoration: underline;
                margin-bottom: 40px;
                border: none;
            }

            div {
                margin-bottom: 12px;

                h3 {
                    font-family: Montserrat, serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0;
                    text-align: left;
                    margin: 0 0 0 12px;

                }

                div {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    border: 2px solid;
                    padding: 2px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }

        button {
            text-align: center;
            padding: 21px 0;
            width: 100%;
            background: #FFFFFF;
            border: 1px solid;
            margin-bottom: 70px;
        }
    }

    .answer {
        padding-left: 50px !important;
    }

    .youtube-program {
        button {
            background: #F0F712;
            padding: 12px 0;
            width: 100%;
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
            border: none
        }
    }
}

.food-content {
    .owl.stage {
        display: flex;
    }

    .food-header {
        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 39px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 30px;
        }
    }

    .food-select {
        div {
            width: 100%;
            margin-right: 40px;

            label {
                font-family: Montserrat, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0;
                text-align: left;
                color: #777777;

            }
        }
    }

    .food-filter {
        padding: 18px 40px;
        background: #F7F7F7;
        margin-bottom: 30px;
    }

    .food-select-item {
        height: 46px;
        max-width: 260px;
        width: inherit;
        border: 1px solid #E6E6E6;
        border-left: 5px solid #F0F712;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
    }

    .food-select-button {
        font-family: Druk Wide Cyr, serif;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0;
        text-align: center;
        white-space: nowrap;
        padding: 12px 28px;
        max-width: 228px;
        width: inherit;
        background: #F0F712;
        border: none;
        max-height: 60px;
    }

    .food-category-content {
        margin-bottom: 70px;

        .owl-stage {
            display: flex;
        }
    }

    .food-category-content:last-of-type {
        margin-bottom: 40px;
    }

    .food-category-content-header {
        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 39px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 8px;
        }
    }

    .food-category-content-alert {
        margin-bottom: 30px;

        p {
            font-family: Montserrat, serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0;
            text-align: left;
            color: #E21A3D;
            margin: 0;
        }

        div {
            a {
                font-family: Montserrat, serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0;
                text-align: left;
                color: #111111;
                text-decoration: underline;
                margin-left: 12px;
            }
        }
    }

    .food-category-content-item {
        padding: 26px;
        border: 1px solid #E6E6E6;
        max-width: 410px;
        width: 100%;
    }

    .food-category-content-item-img {
        max-width: inherit;
        width: 100%;
        margin-bottom: 12px;

        img {
            width: 100%;
            max-width: inherit;
        }
    }

    .food-category-content-item-heads {
        h3 {
            font-family: Druk Wide Cyr, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0;
            text-align: center;
        }

        h4 {
            font-family: Montserrat, serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0;
            text-align: center;
            margin-bottom: 25px;
        }
    }

    .food-category-content-item-content {
        ul {
            padding: 16px 26px;
            display: flex;
            flex-direction: column;
            background: #F7F7F7;
            border-radius: 10px;

            li {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0;
                text-align: left;
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    font-family: Montserrat, serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0;
                    text-align: right;
                }
            }
        }
    }

    .food-category-content-item-button {
        background: none;
        display: flex;
        border: none;
        align-items: center;
        padding: 0;
        margin-top: 16px;
        font-family: Druk Wide Cyr;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 140%;
        text-transform: uppercase;

        span {
            width: 50px;
            height: 50px;
            background: #111111;
            border-radius: 50%;
            display: flex;
            align-items: center;
            margin-right: 17px;
            justify-content: center;
        }

        .selected {
            background: #F0F712 !important;
        }
    }

    .food-ready {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 70px;

        p {
            font-family: Montserrat, serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0;
            text-align: left;
            margin: 0;
        }

        button {
            background: #F0F712;
            max-width: 180px;
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
            border: none;
            padding: 16px 56px;
            margin-left: 30px;
        }
    }
}

.subscribe-header {
    h2 {
        font-family: Druk Wide Cyr, serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 30px;
    }
}

.subscribe-content {

    .content-item {
        max-width: 1298px;
    }


    .subscribe-transform {
        padding: 29px 40px;
        background: #F7F7F7;
        margin-bottom: 70px;

        button {
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
            max-width: 240px;
            width: inherit;
            border: none;
            background: #F0F712;
            padding: 12px 28px;
            white-space: nowrap;
        }

        h3 {
            font-family: Druk Wide Cyr, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
        }
    }

    .subscribe-transform-days {
        max-width: 280px;
        width: inherit;

        div {
            background: linear-gradient(90deg, #E0006C 0%, #FBE900 52.08%, #5DD420 100%);
            border-radius: 5px;
            transform: rotate(-180deg);
            height: 5px;
            margin: 0 0 20px 0;
            max-width: 280px;
            width: inherit;
        }

        p {
            font-family: Montserrat, serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            margin: 0 0 6px 0;

            span {
                font-weight: 700;
            }
        }
    }

    .subscribe-new-plan-header {
        h2 {
            font-family: Druk Wide Cyr, serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 39px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 30px;
        }
    }

    .subscribe-new-plan-price {
        margin-bottom: 70px;

        div {
            max-width: 638px;
            width: inherit;
            height: 280px;
            margin-bottom: 22px;
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: center;
            color: #FFFFFF;

            &:nth-child(odd) {
                margin-right: 22px;
            }

            h4 {
                font-family: Druk Wide Cyr, serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 34px;
                letter-spacing: 0;
                text-align: left;
            }

            p {
                font-family: Montserrat, serif;
                font-size: 44px;
                font-style: normal;
                font-weight: 700;
                line-height: 53px;
                letter-spacing: 0;
                text-align: center;

                span {
                    font-family: Montserrat, serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0;
                    text-align: center;
                    position: absolute;
                }
            }

            a {
                font-family: Druk Wide Cyr, serif;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0;
                text-align: center;
                padding: 16px 56px;
                background: #F0F712;
                border: none;
                color: #111111;
            }
        }
    }
}

#chartjs-tooltip {
    table {
        thead {
            tr {
                th {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 140%;
                    text-align: center;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }

        tbody {
            tr {
                td {
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    text-align: center;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
}

.information-content {
    .preview {
        max-height: 500px !important;
        max-width: 1296px !important;
    }

    #player {
        display: none;
        max-height: 500px !important;
        max-width: 1296px !important;
        width: inherit;
    }


    .personal-head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 40px;
    }

    .personal-head-img {
        margin-right: 54px;
        width: 100%;
        max-width: 116px;
        height: 116px;
        position: relative;

        img:nth-child(1) {
            border-radius: 50%;
            height: 100%;
            object-fit: cover;
            width: 100%;
            object-position: top;
        }

        div {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #F0F712;
            border: 3px solid rgba(255, 255, 255, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                width: 34px;
                height: 34px;
            }

        }
    }

    .personal-head-name,
    .personal-head-gender {
        width: inherit;
        margin-right: 40px;
        max-width: 280px;
        color: #777777;

        label {
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
        }

        select, input {
            font-family: Montserrat, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0;
            text-align: left;
            padding: 16px 0 16px 17px;
            border: 1px solid #E6E6E6;
            border-left: 5px solid #F0F712;
            width: inherit;
        }
    }

    .personal-params {
        .ogp-monitor {
            .calories-count {
                position: absolute;
                right: 32px;

                span {
                    font-family: Druk Wide Cyr;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 140%;
                    color: #111111;
                    margin-bottom: 4px;
                }

                h6 {
                    svg {
                        margin-right: 6px;
                    }

                    font-family: Druk Wide Cyr;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 140%;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .personal-params-header {
        h3 {
            font-family: Druk Wide Cyr, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
            margin-bottom: 20px;
        }
    }

    .personal-params-item {
        padding: 30px 41px 24px 41px;
        box-shadow: 0 4px 40px rgba(34, 34, 34, 0.1);
        border-radius: 10px;
        margin: 0 24px 20px 0;

        p {
            font-family: Montserrat, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0;
            text-align: center;
        }

        input {
            font-family: Montserrat, serif;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 62px;
            letter-spacing: 0;
            text-align: center;
            width: 100%;
            border: none;
            margin-left: 6px;

            &:focus {
                outline: none;
            }

            &[type=number]::-webkit-inner-spin-button {
                height: 0;
            }
        }

        div {
            display: flex;
            justify-content: space-between;

            button {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #F0F712;
                border-radius: 50%;
                background: none;

                &:last-of-type {
                    margin-left: 14px;
                }
            }
        }
    }

    .personal-params-item-update {
        background: #111111;
        padding: 30px 20px 20px 20px;
        box-shadow: 0 4px 40px rgba(34, 34, 34, 0.1);
        border-radius: 10px;
        margin: 0 24px 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        color: #FFFFFF;

        div {
            width: 100%;
        }

        h2 {
            font-family: Montserrat, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0;
        }

        p {
            font-family: Montserrat, serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0;
        }

        button {
            background: #F0F712;
            font-family: Druk Wide Cyr, serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: center;
            border-radius: 22px;
            width: 100%;
            padding: 12px 24px;
            text-transform: uppercase;
        }
    }

    .personal-numbers {
        margin-top: 90px;
        margin-bottom: 8px;
        color: #111111;

        .item {
            h1 {
                font-family: Druk Wide Cyr, serif;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 36px;
                letter-spacing: 0;
                text-align: center;
                margin-bottom: 12px;
            }

            h5 {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0.03em;
                text-align: center;
            }

            p {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0;
                text-align: center;
                margin-bottom: 70px;
            }
        }
    }

    .personal-video {
        margin-bottom: 70px;

        .item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            h2 {
                font-family: Druk Wide Cyr, serif;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 39px;
                letter-spacing: 0;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }

    .line {
        justify-content: center !important;
        border-bottom: 1px solid #E6E6E6;
        margin-bottom: 19px !important;
    }
}

.achievements-content {
    .items {
        flex-wrap: wrap;
        margin-bottom: 70px;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0 auto 0;
            min-width: 236px;
            max-width: 20%;
            width: 100%;

            .item-img {
                width: 150px;
                height: 150px;
                border: 10px solid #F0F712;
                border-radius: 50%;
                padding: 2px;

                .item-img-2 {
                    border: 1px solid;
                    border-radius: 50%;
                    padding: 22px;
                    box-shadow: inset 0 0 4px 4px rgb(141 141 141 / 40%);
                }
            }

            h1 {
                font-family: Druk Wide Cyr, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0;
                text-align: center;
                color: #111111;
                margin: 6px 0 12px 0;
            }

            p {
                font-family: Montserrat, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0;
                text-align: center;
                color: #777777;
                margin-bottom: 40px;
            }
        }
    }
}

//ellipse
.ellipse {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #ffffff30;
    overflow: hidden;
    top: 16px;
    left: 16px;
    border-radius: 50%;
}

//for all
.cardForFitness {
    display: flex;
    flex-direction: column;
    //max-height: 538px;
    height: 100%;
    width: 100%;
    align-items: center;
    max-width: 308px;
    margin: auto;

    .position-relative {
        width: 100%;
    }
}

.imgFitness {
    max-width: 308px;
    height: 320px;
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.cardHeader {
    font-family: Druk Wide Cyr, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    margin: 7px auto;
    color: #111111;
}

ul {
    list-style-type: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 13px;
}

.cardListLeft {
    font-family: Montserrat, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
    margin: 6px 0;
    text-align: left;
    flex: 0 0 56%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #444444;
}

.cardListRight {
    flex: 0 0 44%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-family: Montserrat, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0;
    margin: 6px 0;
    text-align: right;


    svg {
        margin-left: 11px;
    }
}

.cardFooterImg {
    max-height: 32px;
    max-width: 32px;
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    img {
        width: 32px;
        height: 32px;
        object-fit: cover;
    }
}

.cardFooterText {
    font-family: Montserrat, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    margin: auto 8px;
}

.cardWrapper {
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    padding-top: 12px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.cardFooter {
    margin: -0.5rem;
    padding: 14px 17px 14px 16px;
    border-top: 1px solid #E6E6E6;
    position: relative;
    bottom: 0;
    width: 150%;

    svg {
        margin: auto 0;
    }
}

body {
    margin: 0;
    padding: 0;
    color: #111111;
}

.frame {
    display: flex;
    flex-wrap: wrap;
    max-width: 508px;
    max-height: 136px;
}

.framePlaceholder {
    width: 243px;
    height: 136px;
    position: relative;
}

.lock {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    background: #00000050 url(/images/lock.png) no-repeat center;
}

.frameText {
    font-family: Druk Wide Cyr, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}

.frameContent {
    max-width: 245px;
    margin-left: 20px;
}

.btnDisable1 {
    background: #C8C8C8;
    color: #FFFFFF !important;
}

.btnDisable2, .btnDisable3 {
    border: 1px solid #999999 !important;
    color: #999999 !important;
    background: none;
}

.btnTap1 {
    background: #F0FB00;
}

.btnTap2 {
    background: #FEFFD0;
    border: 1px solid #F0F712 !important;
}

.btnTap3 {
    background: #C4C4C4;
    border: 1px solid #111111 !important;
}

.btnNormal1 {
    background: #F0F712;
}

.btnNormal2 {
    background: #FFFFFF;
    border: 1px solid #F0F712 !important;
}

.btnNormal3 {
    background: #FFFFFF;
    border: 1px solid !important;
}

.btnNot {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 56px;
    width: 232px;
    height: 50px;
    font-family: Druk Wide Cyr, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    border-radius: unset !important;

}

.btnNorm {
    border-radius: unset !important;
    width: 233px;
    height: 60px;
    font-family: Druk Wide Cyr, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;

}

.btn {
    margin: 15px;
}

.login-aside {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 101px;
    padding-right: 60px;
    box-shadow: inset -10px 0px 20px #F3F3F3;
    height: calc(100vh - 250px);

    li {
        position: relative;
        display: flex;
        margin-bottom: 16px;

        .item {
            font-family: Montserrat, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 120%;
            padding-right: 10px;
            display: flex;
            align-items: center;
        }

        .item.active {
            font-family: Montserrat, serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 120%;
            display: flex;
            align-items: center;
            padding-right: 10px;
        }

        .completed {
            background-color: #F0F712;

            span {
                &:after {
                    top: 50%;
                    right: 13px;
                    opacity: 1;
                    height: 10px;
                    width: 5px;
                    -webkit-transform: translateY(calc(-50% - 2px)) rotate(
                            45deg
                    );
                    transform: translateY(calc(-50% - 2px)) rotate(
                            45deg
                    );
                    border-right: 2px solid #020202;
                    border-bottom: 2px solid #020202;
                    content: "";
                    position: absolute;
                }
            }
        }

        .custom-ellipse {
            border-radius: 50%;
            border: 2px solid #E6E6E6;
            width: 30px;
            height: 30px;
            font-family: Montserrat, serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: #111111;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .custom-ellipse.active {
            border-radius: 50%;
            border: 2px solid #F0F712;
            width: 30px;
            height: 30px;
            font-family: Montserrat, serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 120%;
            color: #111111;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .underline {
            width: 19px;
            height: 0;
            border: 1px solid #E6E6E6;
            transform: rotate(90deg);
            position: absolute;
            right: 5px;
            bottom: -10px;
        }
    }

}

#app {
    position: relative;
}

.submit-group {
    .next {
        font-family: Druk Wide Cyr, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        border: none;
        color: #111111;
        width: 230px;
        height: 50px;
        background: #F0F712;
    }

    .prev {
        font-family: Druk Wide Cyr, serif;
        font-style: normal;
        font-weight: 500;
        vertical-align: middle;
        font-size: 11px;
        line-height: 48px;
        margin-left: 40px;
        color: #999999;
        width: 230px;
    }
}

.first-step {
    padding-top: 101px;
    padding-left: 60px;
    padding-bottom: 25px;

    h1 {
        font-family: Druk Wide Cyr, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #111111;
        margin-bottom: 40px;
    }

    form {
        .custom-form-group-inline {
            margin-bottom: 30px;
            margin-right: 22px;
            position: relative;
            display: grid;

            input {
                width: 308px;
                height: 50px;
                background: #FFFFFF;
                /* Grey Tones/Line:E6E6E6 */

                border: 1px solid #E6E6E6;
                box-sizing: border-box;
                padding-left: 20px;

                &::placeholder {
                    font-family: Montserrat, serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 120%;
                }
            }

            .left-line {
                position: absolute;
                top: 0;
                left: 0;
                width: 5px;
                height: 50px;
                background: #F0F712;
            }
        }

        .social-form {
            h2 {
                font-family: Montserrat, serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                margin-bottom: 12px;
            }

            .social-btn-grp {
                display: flex;
                margin-bottom: 30px;

                button {
                    background: #FFFFFF;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    margin-left: 22px;
                    width: 198px;
                    height: 46px;
                    border: none;

                    &:first-child {
                        margin: 0;
                    }

                    svg {
                        margin-right: 7px;
                    }
                }

            }
        }

        .terms {
            position: relative;
            margin-bottom: 33px;

            label {
                margin-left: 32px;
            }

            input {
                width: 24px;
                height: 24px;
                opacity: 0;
                position: absolute;
                z-index: 1;
            }

            input:checked + span {
                background: #F0F712;
                border: none;
            }

            input:checked + span:after {
                opacity: 1;
                height: 10px;
                width: 5px;
                top: 50%;
                right: 8px;
                -webkit-transform: translateY(calc(-50% - 2px)) rotate(
                        45deg
                );
                transform: translateY(calc(-50% - 2px)) rotate(
                        45deg
                );
                border-right: 2px solid #020202;
                border-bottom: 2px solid #020202;
                content: "";
                position: absolute;
            }

            span {
                z-index: 0;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 0;
                border: 1px solid;
                left: 0;
            }

            p {
                font-family: Montserrat, serif;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 140%;
                /* identical to box height, or 17px */
            }
        }

    }
}

.second-step {
    padding-top: 101px;
    padding-left: 60px;
    padding-bottom: 25px;

    h1 {
        font-family: Druk Wide Cyr, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #111111;
        margin-bottom: 30px;
    }

    form {
        h2 {
            font-family: Montserrat, serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 12px;
            /* identical to box height, or 20px */
            /* Grey Tones/Mostly Black:111111 */

            color: #111111;
        }

        .gender-select {
            margin-bottom: 30px;
            width: 250px;
            height: 68px;
            background: #F7F7F7;
            border-radius: 10px;
            display: flex;
            padding: 4px 8px;

            .gender-item {
                position: relative;
                width: 100px;
                height: 60px;
                display: flex;

                span {
                    position: absolute;
                    bottom: -25px;
                    font-family: Montserrat, serif;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 0;
                    text-align: center;

                }

                &:last-child {
                    margin-left: auto;
                }

                align-items: center;
                justify-content: center;

                input {
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    position: absolute;
                    z-index: 1;
                    cursor: pointer;
                }

                label {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    transition: all .3s ease;

                    svg {
                        transition: all .3s ease;

                        path {
                            transition: all .3s ease;
                        }

                        &:focus {
                            outline: unset;
                        }
                    }

                    &:focus-visible {
                        outline: unset;
                    }
                }

                input:checked + label {
                    background: #FFFFFF;
                    /* Signal Colours/Style */

                    border: 2px solid #F0F712;
                    box-sizing: border-box;
                    border-radius: 10px;

                    svg {
                        path {
                            fill: black;
                        }
                    }
                }
            }
        }

        .target {
            margin-bottom: 10px;

            h1 {
                font-family: Montserrat, serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0;
                margin-bottom: 12px;
            }

            .target-group {
                display: flex;
                flex-wrap: wrap;

                .item {
                    width: 198px;
                    height: 60px;
                    position: relative;
                    margin-right: 22px;
                    margin-bottom: 30px;

                    input {
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        position: absolute;
                        z-index: 2;
                        cursor: pointer;
                    }

                    label {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        border: 1px solid #E6E6E6;
                        border-radius: 10px;
                        justify-content: space-around;
                        transition: all 0.3s ease;

                        .desc {
                            font-family: Montserrat, serif;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0;
                            text-align: center;
                            width: 106px;
                        }

                        .target-ellipse {
                            border: 1px solid #CACACA;
                            border-radius: 50%;
                            background: #FFFFFF;
                            height: 24px;
                            width: 24px;
                        }
                    }

                    input:checked + label {
                        border: 2px solid #F0F712;
                        box-sizing: border-box;
                        border-radius: 10px;

                        .target-ellipse {
                            background: #F0F712;

                            &:after {
                                top: 50%;
                                right: 18px;
                                opacity: 1;
                                height: 10px;
                                width: 5px;
                                -webkit-transform: translateY(calc(-50% - 2px)) rotate(
                                        45deg
                                );
                                transform: translateY(calc(-50% - 1px)) rotate(
                                        45deg
                                );
                                border-right: 2px solid #020202;
                                border-bottom: 2px solid #020202;
                                content: "";
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }
    }
}

.third-step {
    padding-top: 101px;
    padding-left: 60px;
    padding-bottom: 25px;

    h1 {
        width: 220px;
        height: 28px;
        font-family: Druk Wide Cyr, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        margin-bottom: 40px;
        /* identical to box height, or 28px */


        /* Grey Tones/Mostly Black:111111 */

        color: #111111;
    }

    .personal-data {
        display: flex;
        margin-bottom: 30px;

        .item {
            width: 180px;
            height: 209px;
            background: #FFFFFF;
            /* Shadows/Modal Shadow:40 */
            box-shadow: 0px 4px 40px rgba(34, 34, 34, 0.1);
            border-radius: 10px;
            margin-right: 30px;

            .header {
                padding-top: 30px;

                h3 {
                    font-family: Montserrat, serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    /* identical to box height, or 20px */
                    color: #111111;
                    text-align: center;
                }
            }

            .value {
                padding-top: 10px;
                padding-bottom: 2px;

                h3 {
                    font-family: Montserrat, serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 44px;
                    line-height: 140%;
                    /* identical to box height, or 62px */
                    margin: 0;


                    /* Grey Tones/Mostly Black:111111 */
                    text-align: center;
                    color: #111111;

                }

                .underline {
                    width: 98px;
                    height: 0;
                    border: 1px solid #E6E6E6;
                    margin: 0 auto 19px;
                }
            }

            .plus-minus-group {
                display: flex;
                justify-content: center;

                span {
                    width: 42px;
                    height: 42px;
                    border: 2px solid #F0F712;
                    margin-right: 14px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }
}

.four-step {
    padding-top: 101px;
    padding-left: 60px;
    padding-bottom: 70px;

    h1 {
        width: 138px;
        height: 28px;
        font-family: Druk Wide Cyr, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        /* identical to box height, or 28px */


        /* Grey Tones/Mostly Black:111111 */

        color: #111111;
        margin-bottom: 30px;
    }

    .subscriptions {
        h2 {
            font-family: Montserrat, serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            /* identical to box height, or 20px */

            text-align: center;

            /* Grey Tones/Mostly Black:111111 */

            color: #111111;
            margin-bottom: 12px;

        }

        .item {
            background: #F7F7F7;
            border-radius: 10px;
            position: relative;
            width: 528px;
            height: 70px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            .desc {
                h3 {

                    font-family: Montserrat, serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 140%;
                    /* or 22px */


                    /* Grey Tones/Mostly Black:111111 */

                    color: #111111;

                }

                span {
                    font-family: Montserrat, serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 140%;
                    /* identical to box height, or 20px */


                    /* Grey Tones/Medium Grey:777777 */

                    color: #777777;
                }
            }

            .price {
                h3 {
                    font-family: Montserrat, serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 140%;
                    /* or 25px */

                    text-align: right;
                    color: #111111;
                }
            }


            input {
                width: 100%;
                height: 100%;
                opacity: 0;
                position: absolute;
                z-index: 1;
                cursor: pointer;
            }

            label {
                width: 100%;
                height: 100%;
                background: #F7F7F7;
                border-radius: 10px;
                position: relative;
                margin-bottom: 0;
                display: flex;
                align-items: center;

                .spanCircle {
                    width: 24px;
                    height: 24px;
                    border: 1px solid #CACACA;
                    box-sizing: border-box;
                    border-radius: 50%;
                    z-index: 1;
                    position: relative;
                    background: #FFFFFF;
                }
            }

            input:checked + label {
                background: #ffffff;
                border: 2px solid #F0F712;

                .spanCircle {
                    background: #F0F712;

                    &:after {
                        top: 50%;
                        right: 37%;
                        opacity: 1;
                        height: 10px;
                        width: 5px;
                        -webkit-transform: translateY(calc(-50% - 2px)) rotate(
                                45deg
                        );
                        transform: translateY(calc(-50% - 2px)) rotate(
                                45deg
                        );
                        border-right: 2px solid #020202;
                        border-right: 2px solid #020202;
                        border-bottom: 2px solid #020202;
                        content: "";
                        position: absolute;
                    }
                }
            }

        }
    }

    .payment-subscription {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        h2 {
            font-family: Montserrat, serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            /* identical to box height, or 20px */
            /* Grey Tones/Mostly Black:111111 */
            color: #111111;
            margin-bottom: 12px;
            width: 100%;
        }

        .payment-wrapper {
            display: flex;

            .item {
                width: 140px;
                height: 100px;
                position: relative;
                margin-right: 20px;


                input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;

                }

                label {
                    width: 100%;
                    height: 100%;
                    border: 2px solid #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    span {
                        width: 24px;
                        height: 24px;
                        border: 1px solid #CACACA;
                        box-sizing: border-box;
                        border-radius: 50%;
                        z-index: -1;
                        position: relative;
                    }
                }

                input:checked + label {
                    border: 2px solid #F0F712;

                    span {
                        background: #F0F712;

                        &:after {
                            top: 50%;
                            right: 37%;
                            opacity: 1;
                            height: 10px;
                            width: 5px;
                            -webkit-transform: translateY(calc(-50% - 2px)) rotate(
                                    45deg
                            );
                            transform: translateY(calc(-50% - 2px)) rotate(
                                    45deg
                            );
                            border-right: 2px solid #020202;
                            border-right: 2px solid #020202;
                            border-bottom: 2px solid #020202;
                            content: "";
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
}

.main-slider-item {
    width: 100%;
    height: 100%;

    .img-fluid {
        filter: brightness(0.8);
    }

    .img-small {
        display: none;
    }
}

#owl-carousel {
    .owl-nav {
        button {
            width: 54px;
            height: 54px;
            position: absolute;
            top: 50%;
            left: 10%;

            span {
                display: block;
                position: relative;
                background-image: url(/images/btnLeft.png);
                width: 54px;
                height: 54px;
                background-size: cover;
                transition: all 0.7s ease;

                &:hover {
                    transform: scale(1.1);
                }

            }

            &:last-child {
                left: 90%;

                span {
                    background-image: url(/images/btnRight.png);

                }

            }
        }

    }
}

.sideNav-open {
    z-index: 9000;
    display: flex !important;
    background-color: white;
}

.overlay {
    display: none;
    width: 100%;
    height: 100vh;
    background-color: black;
    overflow: hidden;
    touch-action: none;
    opacity: 0.5;
    z-index: 8000;
    position: fixed;
    top: 0;
    left: 0;

    svg {
        position: absolute;
        top: 50px;
        left: 280px;
    }
}

.sideCalendar {
    z-index: 11;
    position: fixed;
    right: 0;
    background: white;
    max-width: 471px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    display: none;
    min-width: 471px;

    .closeCalendar {
        position: absolute;
        right: 22px;
        top: 22px;
        border: none;
        background: none;
        padding: 0;
        width: 24px;
        height: 24px;
        font-size: 24px;
        line-height: 0;

        span {
            width: 2px;
            height: 100%;
            background: black;
            position: absolute;
            transform: rotate(45deg);
            top: 0;

            &:last-of-type {
                transform: rotate(-45deg);
            }
        }
    }

    .calendarpic {
        margin-bottom: 30px;
    }

    .line {
        width: 100%;
        border-bottom: 1px solid #E6E6E6;
        margin: 20px 0;
    }

    .calendarHeader {
        font-family: Druk Wide Cyr;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin: 40px auto 37px 0;
    }

    .calendarColor {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        position: relative;
        padding-left: 25px;
        margin: 0;

        span {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            border: 1px solid;
            background: #F0F712;
            position: absolute;
            left: 0;
            top: -3px;
        }
    }

    .calendarSection {
        h3 {
            margin-bottom: 20px;
            font-family: Druk Wide Cyr;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;

        }

        .item {
            display: flex;
            margin-bottom: 12px;


            img {
                width: 67px;
                height: 70px;
                margin-right: 20px;
                object-position: top;
                object-fit: cover;
            }

            h2 {
                font-family: Druk Wide Cyr;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
            }
        }
    }
}

.calendarOverlay {
    width: 100%;
    height: 100vh;
    top: 0;
    overflow: hidden;
    position: fixed;
    z-index: 10;
    background: black;
    opacity: .4;
    left: 0;
    display: none;
}

.custom-nav-main-slider {
    position: absolute;
    top: calc(50% - 80px);
    width: 100%;

    .owl-next {
        width: 50px;
        position: absolute;
        right: 12.1%;
        cursor: pointer;
    }

    .owl-prev {
        width: 50px;
        position: absolute;
        left: 12.1%;
        cursor: pointer;
    }
}

.custom-nav-program-slider {
    position: absolute;
    top: calc(50% - 80px);
    width: 100%;

    .owl-next {
        width: 50px;
        position: absolute;
        right: -75px;
        cursor: pointer;
    }

    .owl-prev {
        width: 50px;
        position: absolute;
        left: -75px;
        cursor: pointer;
    }
}

.owl-three {
    max-width: calc(250px * 5);

    .custom-nav-trainer-slider {
        position: absolute;
        top: calc(50% - 80px);
        width: 100%;

        .owl-next {
            width: 50px;
            position: absolute;
            right: -75px;
            cursor: pointer;
        }

        .owl-prev {
            width: 50px;
            position: absolute;
            left: -75px;
            cursor: pointer;
        }
    }
}

.custom-text-comment {
    display: flex;

    div {
        cursor: pointer;
    }
}

.owl-carousel .owl-stage {
    display: flex;
}

.visibleContent {
    visibility: hidden;
    height: 0;
}

.rotateImg {
    transform: rotate(
            180deg
    );
}

#youtubeModal {
    .modal-dialog-centered {
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 0;
        position: inherit;

        .modal-content {
            height: 100%;
        }
    }

    #fatPlayer {
        height: 100%;
    }
}

#youtubeModal {
    .modal-dialog-centered {
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 0;
        position: inherit;

        .modal-content {
            height: 100%;
        }
    }

    #fatPlayer {
        height: 100%;
    }
}

.owl-carousel-food {
    max-width: 100vw;
    overflow: hidden;
    touch-action: none;

    .owl-item {
        min-width: 300px;
    }
}

.owl-stage {
    flex-wrap: wrap;
}
